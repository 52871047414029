import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import "./header.scss"
import DarkModeToggle from "./darkmodetoggle"

const Header = () => {
  /*stores data we are fetching from graphql API
    useStaticQuery allows us to query our graphql API
    provide inside a tagged template literal - in here we can put the query we practiced in graphiql */
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div>
      <header className="header">
        <h1>
          <Link className="title" to="/">
            {data.site.siteMetadata.title}
          </Link>
        </h1>
        <nav>
          <ul className="nav-list">
            <li>
              <Link
                className="nav-item"
                activeClassName="active-nav-item"
                to="/"
              >
                Home
              </Link>
            </li>
            {/* <li>
              <Link
                className="nav-item"
                activeClassName="active-nav-item"
                to="/blog"
              >
                Blog
              </Link>
            </li> */}
            <li>
              <Link
                className="nav-item"
                activeClassName="active-nav-item"
                to="/about"
              >
                About
              </Link>
            </li>
            <li>
              <Link
                className="nav-item"
                activeClassName="active-nav-item"
                to="/projects"
              >
                Projects
              </Link>
            </li>
            <li>
              <Link
                className="nav-item"
                activeClassName="active-nav-item"
                to="/contact"
              >
                Contact
              </Link>
            </li>
            <li className="darkmodetoggle">
              <DarkModeToggle />
            </li>
          </ul>
        </nav>
      </header>
    </div>
  )
}

export default Header
