import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { Seo } from "../components/seo"

const AboutPage = () => {
  return (
    <div>
      <Layout>
        <h1>About</h1>
        <p>
          I majored in Computer Science at Toronto Metropolitan University
          (Formerly known as Ryerson University).
        </p>
        <p>
          I'm experienced with languages such as Java, JavaScript, Python, SQL,
          and HTML/CSS. I have a strong interest in back-end development, as
          well as Dev-Ops/Cloud. I am able to grasp onto working with different
          technologies as needed. I can work efficiently in team environments,
          lead group projects, and work independently as needed.
        </p>
        <p>
          Need a developer? <Link to="/contact">Contact me</Link>
        </p>
      </Layout>
    </div>
  )
}

export default AboutPage

export const Head = () => {
  return <Seo title="About" />
}
