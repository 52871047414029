import React from "react"
import useDarkMode from "@fisch0920/use-dark-mode" //using this fork for now
import { FaMoon, FaSun } from "react-icons/fa"
import "./darkmodetoggle.scss"

const DarkModeToggle = () => {
  const darkMode = useDarkMode(true) // dark mode on by default
  // const darkMode = useDarkMode(localStorage.getItem('darkMode') === true) //localStorage not available during SSR
  //NOTE: Don't SSR since we can't determine the correct initial state until we get to the client.

  return (
    <div>
      <button
        type="button"
        onClick={darkMode.toggle}
        className="darkmodebutton"
      >
        {/* Dark Mode{" "} */}
        {/* {darkMode.value ? <FaSun /> : <FaMoon />} */}
        {/* <p>Use {currentMode} Mode</p> */}
        {/* <div className="icon">{darkMode.value ? <FaSun /> : <FaMoon />}</div> */}
        <p>Change Theme</p>
        <div className="icon">
          <FaSun className="lightmode" />
          <FaMoon className="darkmode" />
        </div>
      </button>
    </div>
  )
}

export default DarkModeToggle
